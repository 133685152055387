$(function() {
  // header
  $(document).ready(function(){
    $('.navbar-fostrap').click(function(){
      $('.nav-fostrap').toggleClass('visible');
      $('.nav-overlay').toggleClass('show');
    });

    $('.mobile-closeBtn').on('click',function(){
      $('.nav-fostrap').removeClass('visible');
      $('.nav-overlay').removeClass('show');
    })

    // Mobile arrow-down
    $('.mobile-menuBtn').on('click',function(){
      var serToggle =  $(this).closest('li').find('.dropdown');
      $(".dropdown").not(serToggle).removeClass('active');
      $('.mobile-menuBtn').not($(this)).removeClass('active');

      if($(this).hasClass('active')){
        $(this).removeClass('active');
        serToggle.removeClass('active');
      }else{
        $(this).addClass('active');
        serToggle.addClass('active');
      }
    });

  });

  // 
  $('.mobile-btn').click(function(){
    $('#mobile-show').toggleClass('active');
  });


  // 贊助單位的swiper
  var swiper = new Swiper(".sponsorSwiper", {
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
    slidesPerView: 5,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 2,
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 5,
      }
    }
  });

  // Login & ForgetPwd switched
  $('.js-forgetPwd a').click(function(){
    $('.form-box').animate({height: "toggle", opacity: "toggle"}, "slow");
  });

  
  // 是否有論文接受
  if($(':input[name=has_paper]').length > 0){
    $(':input[name=has_paper]').on('click', function(){
      $('.paperAccept-data').toggleClass('d-none', $(':input[name=has_paper]:checked').val() == '0');
      $('.paperAccept-data').toggleClass('d-block', $(':input[name=has_paper]:checked').val() == '1');
    });
  }

  // 是否為 IEEE Life 會員
  if($(':input[name=has_memberIEEE]').length > 0){
    $(':input[name=has_memberIEEE]').on('click', function(){
      $('.invoice-data-list').toggleClass('d-none', $(':input[name=has_memberIEEE]:checked').val() == '0');
      $('.invoice-data-list').toggleClass('d-block', $(':input[name=has_memberIEEE]:checked').val() == '1');
    });
  }

  // 眷屬人數
  if($('#dependents-num').length > 0){
    $('#dependents-num').on('change',function(){

      // console.log($("#dependents-num").find("option:selected").text());

      if($("#dependents-num").find("option:selected").text() !== '0'){
        $('.dependents-data').removeClass('d-none').addClass('d-block')
      }else{
        $('.dependents-data').removeClass('d-block').addClass('d-none')
      }

    })
  }

  $("#sel").attr("value",'-sel3');//設置value=-sel3的項目為當前選中項

  // $('#dependents-num').val();
  
});

